<template>
  <section>
    <ConfigurationImageText :data="configurationData.audioVideoData" />
    <ConfigurationImageText :data="configurationData.fileImageData" />
    <ConfigurationImageText :data="configurationData.trackingData" />
    <ConfigurationImageText :data="configurationData.integrationData" />
    <ConfigurationImageText :data="configurationData.routingData" />
    <ConfigurationImageText :data="configurationData.instalationData" />
  </section>
</template>

<script>
import { inject, onMounted, ref } from "vue";
import ConfigurationImageText from "@/modules/configuration/components/configuration-image-text/ConfigurationImageText.vue";
import { useRoute } from "vue-router";

export default {
  components: {
    ConfigurationImageText,
  },

  setup() {
    const t = inject("t");
    const route = useRoute();

    const configurationData = ref({
      audioVideoData: {
        image: require("@/assets/configuration/audio-video.png"),
        class: "confuguration-audio-section",
        cssClasses: [],
        whiteBackground: false,
        heading: t("configuration.audio-video-section.heading"),
        text: [
          t("configuration.audio-video-section.text-1"),
          t("configuration.audio-video-section.text-1"),
        ],
      },
      trackingData: {
        image: require("@/assets/configuration/tracking-clients.png"),
        class: "confuguration-tracking-section",
        cssClasses: [],
        whiteBackground: false,
        heading: t("configuration.tracking-section.heading"),
        text: [
          t("configuration.tracking-section.text-1"),
          t("configuration.tracking-section.text-2"),
        ],
      },
      fileImageData: {
        image: require("@/assets/configuration/file-image.png"),
        class: "confuguration-file-section",
        cssClasses: ["bg-white", "right-image"],
        heading: t("configuration.file-image-section.heading"),
        text: [
          t("configuration.file-image-section.text-1"),
          t("configuration.file-image-section.text-2"),
          t("configuration.file-image-section.text-3"),
        ],
        additionalText: [
          t("configuration.file-image-section.text-4"),
          t("configuration.file-image-section.text-5"),
        ],
      },
      routingData: {
        image: require("@/assets/configuration/routing.png"),
        class: "confuguration-routing-section",
        cssClasses: ["bg-white"],
        heading: t("configuration.routing-section.heading"),
        text: [
          t("configuration.routing-section.text-1"),
          t("configuration.routing-section.text-2"),
        ],
        additionalHeading: t("configuration.routing-section.text-3"),
        additionalText: [
          t("configuration.routing-section.text-4"),
          t("configuration.routing-section.text-5"),
        ],
      },
      integrationData: {
        image: require("@/assets/configuration/integration.png"),
        class: "confuguration-integration-section",
        cssClasses: ["bg-white", "right-image"],
        heading: t("configuration.integration-section.heading"),
        text: [t("configuration.integration-section.text-1")],
      },
      instalationData: {
        image: require("@/assets/configuration/instalation.png"),
        class: "confuguration-instalation-section",
        cssClasses: ["bg-white", "right-image"],
        heading: t("configuration.instalation-section.heading"),
        text: [
          t("configuration.instalation-section.text-1"),
          t("configuration.instalation-section.text-2"),
        ],
      },
    });

    const checkConfigurationParams = () => {
      const queryParam = route.query?.configuration;

      if (queryParam) {
        const htmlELemeqnt = document.querySelector(
          `.confuguration-${queryParam}-section`
        );

        if (htmlELemeqnt) {
          const yOffset = -10;
          const y =
            htmlELemeqnt.getBoundingClientRect().top +
            window.pageYOffset +
            yOffset;

          window.scrollTo({ top: y, behavior: "smooth" });
        }
      }
    };

    onMounted(() => {
      checkConfigurationParams();
    });

    return {
      configurationData,
    };
  },
};
</script>