<template>
  <section :class="data.class">
    <div class="confuguration-section confuguration-heading-section">
      <h2 class="confuguration-heading" v-if="data.heading">
        {{ data.heading }}
      </h2>
      <span class="confuguration-heading-divider"></span>
    </div>

    <div
      class="confuguration-section confuguration-image-text flex flex-wrap py-5 md:py-14"
      :class="data.cssClasses"
    >
      <div class="w-full md:w-1/2 flex justify-center items-center">
        <img :src="data.image" v-if="data.image" />
      </div>
      <div class="w-full md:w-1/2 mt-5 md:mt-0">
        <div
          v-if="data.text && data.text.length"
          class="confuguration-image-text-content"
        >
          <p v-for="(item, index) in data.text" :key="index">{{ item }}</p>
        </div>
      </div>
    </div>

    <div
      class="confuguration-section confuguration-additional-section text-center md:text-left"
      v-if="data.additionalText && data.additionalText.length"
    >
      <h3
        v-if="data.additionalHeading"
        class="text-left confuguration-image-text-subheading"
      >
        {{ data.additionalHeading }}
      </h3>
      <p v-for="(item, index) in data.additionalText" :key="index" class="mb-4">
        {{ item }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
.confuguration-section {
  padding-left: 7.5vw;
  padding-right: 7.5vw;
}

.confuguration-heading {
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  color: #676a6c;
  text-align: left;
  margin-bottom: 0;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
}

.confuguration-heading-divider {
  display: inline-block;
  border-bottom: 2px solid #17b191;
  width: 55px;
  text-align: left;
}
</style>

<style lang="scss" scoped>
.confuguration-image-text {
  background-color: #f8f8f8;
}

.confuguration-image-text.bg-white {
  background-color: #fff;
}

.confuguration-image-text.right-image {
  flex-direction: row-reverse;
}

.confuguration-heading-section {
  text-align: left;
  margin-top: 80px;
  margin-bottom: 70px;
}

.confuguration-additional-section,
.confuguration-image-text-content {
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #626262;
  font-family: Poppins, sans-serif;

  p {
    margin-bottom: 20px;
  }
}

.confuguration-image-text-subheading {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .confuguration-additional-section,
  .confuguration-image-text-content,
  .confuguration-heading-section,
  .confuguration-heading {
    text-align: center;
  }

  .confuguration-additional-section,
  .confuguration-image-text-content {
    font-weight: 400;
    font-size: 16px;
  }

  .confuguration-heading-section {
    margin-top: 40px;
    margin-bottom: 30px;
  }
}
</style>